import {
    type Dispatch,
    memo,
    type ReactNode,
    type SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { Modal } from '@components/common';
import LogoWithText from 'public/logo-with-text.svg';
import {
    cookieConsentModalData,
    cookieEvents,
    cookieInformation,
    keyToLabelMap,
    BUTLERAPP_COOKIE_NAME,
} from '@data/cookie-consent.ts';
import { Switch } from '@components/common/Switch';
import styles from './CookieConsentModal.module.scss';
import './fonts.css';

const onVoid = () => {};

const contentProps = { style: { borderRadius: '4px', padding: 0 } };
const primaryButtonStyle = { padding: '1rem 1.5rem', fontSize: '1.3rem' };

const setCookie = (value: string) => {
    Cookies.set(BUTLERAPP_COOKIE_NAME, value, {
        expires: 90,
    });
};

const CookieConsentModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [route, setRoute] = useState('main');
    const [acceptedEventIds, setAcceptedEventIds] = useState(Object.keys(cookieEvents));

    const onClose = useCallback(() => {
        console.debug(
            'Sending the version cookie to Hotjar',
            // @ts-ignore
            window?.hj,
            localStorage.getItem('timestampId'),
        );

        // @ts-ignore
        const appVersion = ('; ' + document.cookie)
            ?.split(`; appversion=`)
            .pop()
            .split(';')[0]
            .toString();

        // @ts-ignore
        window?.hj?.('identify', localStorage.getItem('timestampId'), {
            'Version Cookie': appVersion,
        });

        setIsOpen(false);
    }, []);

    const onAccept = useCallback(() => {
        Object.values(cookieEvents).forEach(event => (window as any).dataLayer?.push({ event }));

        setCookie(JSON.stringify(Object.keys(cookieEvents)));

        onClose();
    }, [onClose]);

    const onAcceptSelected = useCallback(() => {
        acceptedEventIds.forEach(id => {
            const event = cookieEvents[id as keyof typeof cookieEvents];
            if (!event) return;

            (window as any).dataLayer?.push({ event });
        });

        setCookie(JSON.stringify(acceptedEventIds));

        onClose();
    }, [onClose, acceptedEventIds]);

    const onDecline = useCallback(() => {
        setCookie(JSON.stringify([]));
        onClose();
    }, [onClose]);

    const onToggleEvent = useCallback(
        (eventId: string | string[]) => {
            setAcceptedEventIds(prev => {
                if (typeof eventId === 'string') {
                    return prev.includes(eventId)
                        ? prev.filter(item => item !== eventId)
                        : [...prev, eventId];
                }

                return Array.from(new Set([...prev, ...eventId])).reduce((acc: string[], id) => {
                    // if id is included in eventId array and prev, we want to remove it
                    if (eventId.includes(id) && prev.includes(id)) return acc;

                    return [...acc, id];
                }, []);
            });
        },
        [setAcceptedEventIds],
    );

    useEffect(() => {
        const cookieConsent = Cookies.get(BUTLERAPP_COOKIE_NAME);

        if (cookieConsent) {
            const ids = JSON.parse(cookieConsent);

            if (!Array.isArray(ids)) return;

            ids.forEach(id => {
                const event = cookieEvents[id as keyof typeof cookieEvents];
                if (!event) return;

                (window as any).dataLayer?.push({ event });
            });

            return;
        }

        setIsOpen(true);
    }, []);

    if (!isOpen) return null;

    return (
        <Modal isOpen onClose={onVoid} contentProps={contentProps}>
            {route === 'main' && (
                <CookieConsentMain
                    onAccept={onAccept}
                    onAcceptSelected={onAcceptSelected}
                    onDecline={onDecline}
                    setRoute={setRoute}
                />
            )}
            {route === 'customize' && (
                <CookieConsentCustomize
                    onAccept={onAccept}
                    onAcceptSelected={onAcceptSelected}
                    onDecline={onDecline}
                    setRoute={setRoute}>
                    {cookieInformation.map(item => (
                        <CookieInfoCollapsibleItem
                            key={item.id}
                            {...item}
                            acceptedEventIds={acceptedEventIds}
                            onToggleEvent={onToggleEvent}
                        />
                    ))}
                </CookieConsentCustomize>
            )}
        </Modal>
    );
};

const CookieConsentMain = memo(
    ({
        onAccept,
        onAcceptSelected,
        onDecline,
        setRoute,
    }: {
        onAccept: () => void;
        onAcceptSelected: () => void;
        onDecline: () => void;
        setRoute: Dispatch<SetStateAction<string>>;
    }) => {
        const onClickSetIndividualCookies = useCallback(() => setRoute('customize'), [setRoute]);

        return (
            <div className={styles.cookieConsentMainBox}>
                <img
                    className={styles.cookieConsentModalLogo}
                    src={LogoWithText.src}
                    alt="ButlerappLogo"
                />
                <span className={styles.cookieConsentModalTitle}>
                    {cookieConsentModalData.title}
                </span>
                <p className={styles.cookieConsentModalDescription}>
                    {cookieConsentModalData.description}
                </p>
                <ul className={styles.cookieConsentModalAreasList}>
                    {cookieConsentModalData.areas.map(item => (
                        <li key={item} className={styles.cookieConsentModalAreasListItem}>
                            {item}
                        </li>
                    ))}
                </ul>

                <button className={styles.cookieConsentModalPrimaryButton} onClick={onAccept}>
                    {cookieConsentModalData.primaryAction}
                </button>
                <button
                    className={styles.cookieConsentModalSecondaryButton}
                    onClick={onAcceptSelected}>
                    {cookieConsentModalData.secondaryAction}
                </button>

                <button className={styles.cookieConsentModalDeclineButton} onClick={onDecline}>
                    {cookieConsentModalData.declineAction}
                </button>

                <button
                    className={styles.cookieConsentModalSetIndividualButton}
                    onClick={onClickSetIndividualCookies}>
                    {cookieConsentModalData.customizeAction}
                </button>

                <ul className={styles.cookieConsentModalLinksContainer}>
                    {[
                        <li key="cookie-details" className={styles.cookieConsentModalLink}>
                            <button onClick={onClickSetIndividualCookies}>Cookie-Details</button>
                        </li>,
                        ...cookieConsentModalData.links.map(item => (
                            <li key={item.href} className={styles.cookieConsentModalLink}>
                                <a href={item.href}>{item.name}</a>
                            </li>
                        )),
                    ]}
                </ul>
            </div>
        );
    },
);

const CookieConsentCustomize = memo(
    ({
        onAccept,
        onAcceptSelected,
        onDecline,
        setRoute,
        children,
    }: {
        onAccept: () => void;
        onAcceptSelected: () => void;
        onDecline: () => void;
        setRoute: Dispatch<SetStateAction<string>>;
        children: ReactNode;
    }) => {
        return (
            <div className={styles.cookieConsentCustomize}>
                <div className={styles.cookieConsentCustomizeHeader}>
                    <img src={LogoWithText.src} alt="ButlerappLogo" />
                    <div className={styles.cookieConsentCustomizeHeaderContents}>
                        <span>{cookieConsentModalData.title}</span>
                        <p>{cookieConsentModalData.customizeInstructions}</p>

                        <div className={styles.cookieConsentCustomizeHeaderButtonsContainer}>
                            <div className={styles.cookieConsentButtonsRow}>
                                <button
                                    className={clsx(
                                        styles.cookieConsentModalPrimaryButton,
                                        styles.cookieConsentModalButtonSmall,
                                    )}
                                    onClick={onAccept}
                                    style={primaryButtonStyle}>
                                    {cookieConsentModalData.primaryAction}
                                </button>
                                <button
                                    className={clsx(
                                        styles.cookieConsentModalSecondaryButton,
                                        styles.cookieConsentModalButtonSmall,
                                    )}
                                    onClick={onAcceptSelected}>
                                    {cookieConsentModalData.secondaryAction}
                                </button>
                            </div>
                            <ul className={styles.cookieConsentModalLinksContainer}>
                                <li className={styles.cookieConsentModalLink}>
                                    <button onClick={() => setRoute('main')}>Zurück</button>
                                </li>
                                <li className={styles.cookieConsentModalLink}>
                                    <button onClick={onDecline}>
                                        Nur essenzielle Cookies akzeptieren
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {children}
            </div>
        );
    },
);

const CookieInfoCollapsibleItem = memo(
    ({
        title,
        description,
        cookies,
        isOptional,
        acceptedEventIds,
        onToggleEvent,
    }: (typeof cookieInformation)[0] & {
        acceptedEventIds: string[];
        onToggleEvent: (id: string | string[]) => void;
    }) => {
        const [isExpanded, setIsExpanded] = useState(false);

        const onToggle = useCallback(() => setIsExpanded(prev => !prev), []);

        const isAllEnabled = useMemo(
            () =>
                cookies.filter(cookie => acceptedEventIds.includes(cookie.id)).length ===
                cookies.length,
            [cookies, acceptedEventIds],
        );

        const onToggleAll = useCallback(() => {
            onToggleEvent(cookies.map(item => item.id));
        }, [cookies, onToggleEvent, acceptedEventIds]);

        return (
            <div className={styles.cookieInfoCollapsibleCard}>
                <div className={styles.cookieInfoCollapsibleCardHeader}>
                    <strong>
                        {title} ({cookies.length})
                    </strong>
                    {isOptional && (
                        <Switch
                            label={isAllEnabled ? 'An' : 'Aus'}
                            checked={isAllEnabled}
                            onChange={onToggleAll}
                            labelPosition="left"
                        />
                    )}
                </div>
                <p className={styles.cookieInfoCollapsibleCardDescription}>{description}</p>

                <button
                    className={clsx(
                        styles.cookieInfoCollapsibleCardExpandButton,
                        styles.cookieConsentModalSetIndividualButton,
                    )}
                    onClick={onToggle}>
                    {isExpanded
                        ? cookieConsentModalData.collapseAction
                        : cookieConsentModalData.expandAction}
                </button>

                {isExpanded && (
                    <div className={styles.cookieInfoSectionsContainer}>
                        {cookies.map(item => (
                            <ul key={item.id} className={styles.cookieInfoSection}>
                                {[
                                    isOptional && (
                                        <li key={'accept'} className={styles.cookieInfoListItem}>
                                            <span className={styles.cookieInfoListItemLabel}>
                                                Akzeptieren
                                            </span>
                                            <Switch
                                                label={
                                                    acceptedEventIds.includes(item.id)
                                                        ? 'An'
                                                        : 'Aus'
                                                }
                                                checked={acceptedEventIds.includes(item.id)}
                                                onChange={() => {
                                                    onToggleEvent(item.id);
                                                }}
                                            />
                                        </li>
                                    ),
                                    // loop over the keys to display each property as a row
                                    ...Object.keys(item)
                                        .filter(_key => _key !== 'id')
                                        .map(key => (
                                            <li key={key} className={styles.cookieInfoListItem}>
                                                <span className={styles.cookieInfoListItemLabel}>
                                                    {keyToLabelMap[
                                                        key as keyof typeof keyToLabelMap
                                                    ] || key}
                                                </span>
                                                <span className={styles.cookieInfoListItemValue}>
                                                    {item[key as keyof typeof item]}
                                                </span>
                                            </li>
                                        )),
                                ]}
                            </ul>
                        ))}
                    </div>
                )}
            </div>
        );
    },
);

export default memo(CookieConsentModal);
