export const cookieEvents = {
    'facebook-pixels': 'CookieOptInFacebookPixel',
    'google-consent-mode_ad_storage': 'OptInGoogleConsentMode_ad_storage',
    'google-consent-mode_ad_user_data': 'OptInGoogleConsentMode_ad_user_data',
    'google-consent-mode_ad_personalization': 'OptInGoogleConsentMode_ad_personalization',
    'google-consent-mode_analytics_storage': 'OptInGoogleConsentMode_analytics_storage',
    'google-consent-mode_functionality_storage': 'OptInGoogleConsentMode_functionality_storage',
    'google-consent-mode_personalization_storage': 'OptInGoogleConsentMode_personalization_storage',
    'google-consent-mode_security_storage': 'OptInGoogleConsentMode_security_storage',
    'google-ads-remarketing': 'CookieOptInGoogleAdsRemarketing',
    'google-analytics': 'CookieOptInGoogleAnalytics',
    'google-conversion-linker': 'CookieOptInGoogleConversionLinker',
    'linkedIn-insight': 'CookieOptInLinkedInInsight',
    'matomo': 'CookieOptInMatomo',
    'hotjar': 'CookieOptInHotjar'
};

export const cookieConsentModalData = {
    title: 'Datenschutzeinstellungen',
    description:
        'Um die einwandfreie Darstellung der Webseite zu garantieren, um unsere Webseite für Sie zu verbessern, und um Ihnen später personenbezogene individuelle Angebote zu präsentieren (auch auf anderen Webseiten) setzen wir auf unserer Webseite Cookies. Für mehr Infos zu unseren Cookies, schauen Sie gerne unsere Datenschutzerklärung an.',
    areas: [' Essenziell', ' Statistiken', ' Marketing'],
    primaryAction: 'Ich akzeptiere',
    secondaryAction: 'Nur Auswahl speichern',
    declineAction: 'Nur essenzielle Cookies akzeptieren',
    customizeAction: 'Individuelle Datenschutzeinstellungen',
    links: [
        {
            name: 'Datenschutzerklärung',
            href: '/datenschutz',
        },
        {
            name: 'Impressum',
            href: '/impressum',
        },
    ],

    customizeInstructions:
        'Hier finden Sie eine Übersicht über alle verwendeten Cookies. Sie können Ihre Einwilligung zu ganzen Kategorien geben oder sich weitere Informationen anzeigen lassen und so nur bestimmte Cookies auswählen.',

    expandAction: 'Cookie-Informationen anzeigen',
    collapseAction: 'Cookie-Informationen ausblenden',
};

export const cookieInformation = [
    {
        id: 'essentials',
        title: 'Essenziell',
        description:
            'Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.',
        isOptional: false,
        cookies: [
            {
                id: 'butlerapp-cookie',
                name: 'Butlerapp Cookie',
                provider: 'Eigentümer dieser Website',
                purpose:
                    'Speichert die Einstellungen der Besucher, die in der Cookie Box von  Cookie ausgewählt wurden.',
                cookieName: 'butlerapp_cookie_pref',
                cookieDuration: '3 Monate',
            },
            {
                id: 'tag-manager',
                name: 'Google Tag Manager',
                provider: 'Google Ireland Limited, Gordon House,Barrow Street, Dublin 4, Ireland',
                purpose:
                    'Cookie von Google zur Steuerung der erweiterten Script-und Ereignisbehandlung.',
                dataProtection: 'https://policies.google.com/privacy?hl=de',
                cookieName: '_ga,_gat*,_gid',
                cookieDuration: '2 Jahre',
            },
            {
                id: 'intercom',
                name: 'Intercom',
                provider: 'Intercom Inc.',
                purpose:
                    'Mithilfe dieser Technologie können Besuchern dieser Webseite in einen direkten Kontakt mit dem Service Team treten.',
                dataProtection: 'https://www.intercom.com/legal/terms-and-policies#privacy',
                cookieName: 'intercom-id-*,intercom-session-*',
                cookieDuration: '2 Jahre',
            },
        ],
    },
    {
        id: 'statistics',
        title: 'Statistiken',
        description:
            'Statistik Cookies erfassen Informationen anonym. Diese Informationen helfen uns zu verstehen, wie unsere Besucher unsere Website nutzen.',
        isOptional: true,
        cookies: [
            {
                id: 'google-analytics',
                name: 'Google Analytics',
                provider: 'Google Ireland Limited, Gordon House,Barrow Street, Dublin 4, Ireland',
                purpose:
                    'Cookie von Google für Website-Analysen. Erzeugt statistische Daten darüber, wie der Besucher die Website nutzt.',
                dataProtection: 'https://policies.google.com/privacy?hl=de',
                cookieName: '_ga,_gat*,_gid',
                cookieDuration: '2 Jahre',
            },
            {
                id: 'matomo',
                name: 'Matomo',
                provider: 'Matomo',
                purpose:
                    'Cookie von Matomo für Website-Analysen. Erzeugt statistische Daten darüber, wie der Besucher die Website nutzt.',
                dataProtection: 'https://butlerapp.de/impressum',
                cookieName: '_pk_id.*, _pk_ses.*',
                cookieDuration: '13 Monate',
            },
            {
                id: 'hotjar',
                name: 'Hotjar',
                provider: 'Hotjar Ltd.',
                purpose:
                    'Hotjar ist ein Analysewerkzeug für das Benutzerverhalten von Hotjar Ltd. Wir verwenden Hotjar, um zu verstehen, wie Benutzer mit unserer Website interagieren.',
                dataProtection: 'https://www.hotjar.com/legal/policies/privacy/',
                cookieName:
                    '_hjSessionUser_,* _hjAbsoluteSessionInProgress, _hjSession_*, _hjFirstSeen, _hjIncludedInPageviewSample, _hjIncludedInSessionSample',
                cookieDuration: 'Sitzung / 1 Jahr',
            },
        ],
    },
    {
        id: 'marketing',
        title: 'Marketing',
        description:
            'Marketing-Cookies werden von Drittanbietern oder Publishern verwendet, um personalisierte Werbung anzuzeigen. Sie tun dies, indem sie Besucher über Websites hinweg verfolgen.',
        isOptional: true,
        cookies: [
            {
                id: 'facebook-pixels',
                name: 'Facebook Pixels',
                provider: 'Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland',
                purpose:
                    'Cookie von Facebook das für Website-Analysen, Ad-Targeting und Anzeigenmessung verwendet wird.',
                dataProtection: 'https://www.facebook.com/policies/cookies',
                cookieName: '_fbp',
                cookieDuration: '3 Monate',
            },
            {
                id: 'google-ads-remarketing',
                name: 'Google Ads Remarketing',
                provider: 'Google LLC',
                purpose:
                    'Nutzern nur Werbeanzeigen präsentieren, die potentiell deren Interessen entsprechen.',
                dataProtection: 'https://policies.google.com/privacy?hl=de',
                cookieName: '',
                cookieDuration: '',
            },
            {
                id: 'google-conversion-linker',
                name: 'Google Conversion Linker',
                provider: 'Google LLC',
                purpose: 'Dieser Service speichert Klickdaten, um Conversions effektiv zu messen.',
                dataProtection: 'https://policies.google.com/privacy?hl=en',
                cookieName: '_gcl_au, _gcl_aw',
                cookieDuration: '90 Tage',
            },
            {
                id: 'linkedIn-insight',
                name: 'LinkedIn Insight',
                provider: 'LinkedIn Corporation',
                purpose:
                    'Mithilfe dieser Technologie können Besuchern dieser Webseite personalisierte Werbeanzeigen auf LinkedIn ausgespielt werden.',
                dataProtection: 'https://www.linkedin.com/legal/privacy-policy',
                cookieName: '_ga,_gat*,_gid',
                cookieDuration: '6 Monate',
            },
        ],
    },
    {
        id: 'google-consent-mode',
        title: 'Google Consent Mode',
        description:
            'Legt die Zustimmung zur Übermittlung und Speicherung von Nutzerdaten an Google fest.',
        isOptional: true,
        cookies: [
            {
                id: 'google-consent-mode_ad_storage',
                name: 'ad_storage',
                provider: 'Google LLC',
                purpose:
                    'Speicherung und Auslesung (z. B. von Cookies oder anderen Parametern) von/durch Google im Zusammenhang mit Werbung von Google.',
                dataProtection: 'https://policies.google.com/privacy?hl=de',
                googleConsentModeParameters: 'ad_storage',
                googleConsentModeValues: 'granted (bewilligt)',
            },
            {
                id: 'google-consent-mode_ad_user_data',
                name: 'ad_user_data',
                provider: 'Google LLC',
                purpose:
                    'Senden von Nutzerdaten an Google im Zusammenhang mit Werbung von Google.',
                dataProtection: 'https://policies.google.com/privacy?hl=de',
                googleConsentModeParameters: 'ad_user_data',
                googleConsentModeValues: 'granted (bewilligt)',
            },
            {
                id: 'google-consent-mode_ad_personalization',
                name: 'ad_personalization',
                provider: 'Google LLC',
                purpose:
                    'Speicherung und Auslesung (z. B. von Cookies oder anderen Parametern) von/durch Google im Zusammenhang mit personalisierte Werbung von Google.',
                dataProtection: 'https://policies.google.com/privacy?hl=de',
                googleConsentModeParameters: 'ad_personalization',
                googleConsentModeValues: 'granted (bewilligt)',
            },
            {
                id: 'google-consent-mode_analytics_storage',
                name: 'analytics_storage',
                provider: 'Google LLC',
                purpose:
                    'Speicherung und Auslesung (z. B. von Cookies oder anderen Parametern) von/durch Google im Zusammenhang mit der Analyse, z. B. der Besuchsdauer.',
                dataProtection: 'https://policies.google.com/privacy?hl=de',
                googleConsentModeParameters: 'analytics_storage',
                googleConsentModeValues: 'granted (bewilligt)',
            },
            {
                id: 'google-consent-mode_functionality_storage',
                name: 'functionality_storage',
                provider: 'Google LLC',
                purpose:
                    'Speicherung und Auslesung (z. B. von Cookies oder anderen Parametern) von/durch Google die die Funktionalität der Website oder App unterstützen, z. B. Spracheinstellungen.',
                dataProtection: 'https://policies.google.com/privacy?hl=de',
                googleConsentModeParameters: 'functionality_storage',
                googleConsentModeValues: 'granted (bewilligt)',
            },
            {
                id: 'google-consent-mode_personalization_storage',
                name: 'personalization_storage',
                provider: 'Google LLC',
                purpose:
                    'Speicherung und Auslesung (z. B. von Cookies oder anderen Parametern) von/durch Google die die Funktionalität der Website oder App unterstützen, z. B. Spracheinstellungen.',
                dataProtection: 'https://policies.google.com/privacy?hl=de',
                googleConsentModeParameters: 'personalization_storage',
                googleConsentModeValues: 'granted (bewilligt)',
            },
            {
                id: 'google-consent-mode_security_storage',
                name: 'security_storage',
                provider: 'Google LLC',
                purpose:
                    'Speicherung und Auslesung (z. B. von Cookies oder anderen Parametern) von/durch Google im Zusammenhang mit der Sicherheit, z. B. Authentifizierungsfunktionen, Betrugsprävention und sonstiger Nutzerschutz.',
                dataProtection: 'https://policies.google.com/privacy?hl=de',
                googleConsentModeParameters: 'security_storage',
                googleConsentModeValues: 'granted (bewilligt)',
            },
        ],
    },
];

export const keyToLabelMap = {
    name: 'Name',
    provider: 'Anbieter',
    purpose: 'Zweck',
    dataProtection: 'Datenschutzerklärung',
    cookieName: 'Cookie Name',
    cookieDuration: 'Cookie Laufzeit',
    googleConsentModeParameters: 'Parameter Name',
    googleConsentModeValues: 'Übermittelter Wert an Google',
};

// DO NOT CHANGE
export const BUTLERAPP_COOKIE_NAME = 'butlerapp_cookie_pref';
